div.navigation-container {
  left: 0;
  bottom: 0;
  z-index: 20;
  width: 100%;
  height: 50px;
  display: flex;
  text-align: center;
  font-size: 3em;
  position: fixed;
  overflow: hidden;
  flex-flow: column;
  text-align: center;
  transition: all 0.5s;
  background: #333333;
}

div.navigation-container * {
  align-self: center;
}

div.navigation-container.open {
  height: 100%;
}

div.navigation-container div.links a,
div.navigation-container div.links a:visited {
  display: block;
  margin-bottom: 15px;
  color: #00aeef;
  text-decoration: none;
}

div.navigation-container div.links a.active {
  color: #ec008c;
  pointer-events: none;
}

div.navigation-container div.links {
  position: absolute;
  bottom: 90px;
}

div.navigation-container.open div.links a,
div.navigation-container.open div.links div {
  opacity: 0;
}

div.navigation-container.open div.links a:nth-of-type(1) {
  animation: fadeIn 0.5s 0.2s forwards;
}

div.navigation-container.open div.links a:nth-of-type(2) {
  animation: fadeIn 0.5s 0.25s forwards;
}

div.navigation-container.open div.links a:nth-of-type(3) {
  animation: fadeIn 0.5s 0.375s forwards;
}

div.navigation-container.open div.links a:nth-of-type(4) {
  animation: fadeIn 0.5s 0.5s forwards;
}

div.navigation-container.open div.links div:nth-of-type(1) {
  animation: fadeIn 0.5s 0.625s forwards;
}

div.navigation-container div.navbtn {
  bottom: 0;
  z-index: 25;
  width: 50px;
  height: 50px;
  position: absolute;
  display: flex;
  justify-content: center;
}

div.navigation-container .desktop {
  display: none;
}

div.navigation-container div.navbtn img {
  width: 50%;
  align-self: center;
  position: absolute;
  transition: all 0.5s;
}

div.navigation-container div.navbtn img.h-icon,
div.navigation-container div.navbtn img.x-icon {
  opacity: 0;
}

div.navigation-container div.navbtn img.h-icon.on,
div.navigation-container.open div.navbtn img.x-icon,
div.navigation-container div.navbtn img.x-icon.on {
  opacity: 1;
}

div.navigation-container.open div.navbtn img.b-icon,
div.navigation-container div.navbtn img.b-icon.off,
div.navigation-container div.navbtn img.h-icon.on + img.b-icon,
div.navigation-container div.navbtn img.x-icon.on + img.b-icon {
  opacity: 0;
}

div.navigation-container div.social-icons {
  display: flex;
  margin-top: 35px;
  flex-direction: row;
  justify-content: center;
}

div.navigation-container div.social-icons a {
  display: inherit;
  margin: 0 !important;
  padding: 0 !important;
}

div.navigation-container div.social-icons img {
  width: 20px;
  margin: 0 15px;
  align-self: center;
}



/* DESKTOP */

@media(min-width: 750px) {
  div.navigation-container {
    left: 50px;
    top: 25px;
    width: calc(100% - 100px);
    height: 50px;
    display: flex;
    text-align: center;
    font-size: 3em;
    position: fixed;
    overflow: unset;
    flex-flow: row;
    text-align: left;
    transition: none;
    background: transparent;
  }
  
  div.navigation-container * {
    align-self: flex-start;
  }

  div.navigation-container.open {
    height: 50px;
  }

  div.navigation-container div.links > a:first-of-type {
    display: none;
  }

  div.navigation-container div.links a,
  div.navigation-container div.links a:visited {
    display: block;
    margin: 0 25px;
    color: #00aeef;
    transition: all 0.5s;
    text-decoration: none;
  }

  div.navigation-container.home div.links a,
  div.navigation-container.home div.links a:visited {
    color: #ffffff;
  }

  div.navigation-container div.links a:hover {
    color: #ec008c;
  }

  div.navigation-container.home div.links a:hover {
    color: #000000;
  }

  div.navigation-container div.links a.active {
    color: #ec008c;
    font-weight: 500;
    pointer-events: none;
  }

  div.navigation-container div.links {
    width: calc(100% - 50px);
    height: 50px;
    left: 50px;
    bottom: unset;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 24px;
  }

  div.links.hide-nav {
    opacity: .125;
    pointer-events: none;
  }

  div.navigation-container div.links * {
    align-self: center;
  }

  div.navigation-container.open div.links a,
  div.navigation-container.open div.links div {
    opacity: 1;
  }

  div.navigation-container.open div.links div:nth-of-type(1),
  div.navigation-container.open div.links a:nth-of-type(1),
  div.navigation-container.open div.links a:nth-of-type(2),
  div.navigation-container.open div.links a:nth-of-type(3),
  div.navigation-container.open div.links a:nth-of-type(4) {
    animation: unset;
  }

  div.navigation-container div.navbtn {
    bottom: 0;
    width: 50px;
    height: 50px;
    position: absolute;
    display: flex;
    justify-content: center;
    background: #000000;
  }
  
  div.navigation-container .mobile {
    display: none;
  }
  
  div.navigation-container .desktop {
    display: unset;
  }

  div.navigation-container div.navbtn img {
    width: 50%;
    align-self: center;
    position: absolute;
    opacity: 0;
    transition: all 0.5s;
  }

  div.navigation-container.open div.navbtn img.x-icon,
  div.navigation-container div.navbtn img.h-icon.on,
  div.navigation-container div.navbtn img.h-icon,
  div.navigation-container div.navbtn img.x-icon,
  div.navigation-container.open div.navbtn img.x-icon.on,
  div.navigation-container div.navbtn img.x-icon.on {
    opacity: 0;
  }
  
  div.navigation-container div.navbtn img.b-icon.off,
  div.navigation-container div.navbtn img.x-icon.on + img.b-icon.off,
  div.navigation-container.open div.navbtn img.x-icon.on + img.b-icon.off,
  div.navigation-container div.navbtn img.x-icon.on + img.b-icon,
  div.navigation-container div.navbtn img.h-icon.on + img.b-icon,
  div.navigation-container.open div.navbtn img.b-icon,
  div.navigation-container div.navbtn img.b-icon {
    opacity: 1;
    cursor: pointer;
  }


  div.navigation-container div.social-icons {
    display: flex;
    margin: 0;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    right: 0;
  }

  div.navigation-container div.social-icons a {
    display: inherit;
    margin: 0 !important;
    padding: 0 !important;
  }

  div.navigation-container div.social-icons img {
    width: 20px;
    margin: 0 0 0 15px;
    align-self: center;
  }
  
  div.navigation-container.home div.social-icons img {
    transition: all 0.5s;
  }

  div.navigation-container.home div.social-icons img {
    filter: brightness(0) invert(1);
  }

  div.navigation-container div.social-icons img:hover {
    filter: hue-rotate(-228deg);
  }

  div.navigation-container.home div.social-icons img:hover {
    filter: brightness(0) invert(0);
  }

  div.navigation-container.work,
  div.navigation-container.about {
    position: relative;
    margin-bottom: 100px;
  }
}







@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}