div.home-container {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  color: #ffffff;
  text-align: center;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  background-color: #ec008c;
}

div.home-container * {
  align-self: center;
}

div.home-container h1 {
  padding: 0;
  margin: 0 0 5px 0;
  font-size: 62px;
  font-weight: 300;
}

div.home-container p {
  margin: 0;
  padding: 0;
  letter-spacing: 1px;
  font-size: 25px;
  line-height: 1.2;
}