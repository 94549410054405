@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap);
html {
  margin: 0;
  padding: 0;
  line-height: 0;
}

body {
  margin: 0;
  padding: 0;
  font-size: 15px;
  line-height: normal;
  font-weight: 300;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-weight: 700;
  margin: 0;
  padding: 0;
}

.prevent-scroll {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
}
.App {
  padding: 0;
  margin: 0;
}
div.home-container {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  color: #ffffff;
  text-align: center;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  background-color: #ec008c;
}

div.home-container * {
  align-self: center;
}

div.home-container h1 {
  padding: 0;
  margin: 0 0 5px 0;
  font-size: 62px;
  font-weight: 300;
}

div.home-container p {
  margin: 0;
  padding: 0;
  letter-spacing: 1px;
  font-size: 25px;
  line-height: 1.2;
}
div.about-container {
  padding: 25px 25px 75px;
}

div.about-container h1 {
  font-size: 2.5em;
  font-weight: 200;
  text-align: center;
}

div.about-container p strong {
  font-weight: 500;
}

div.about-container p strong.cv-head {
  display: block;
  padding: 25px 0 0;
}

div.about-container a,
div.about-container a:visited {
  color: #00aeef;
  text-decoration: none;
  transition: all 0.5s;
}

div.about-container a:hover {
  color: #ec008c;
}

div.about-container ul {
  margin-bottom: 35px;
}

/* div.about-container a::after {
  content: '>';
  display: inline-block;
  font-size: .7em;
  padding-left: 3px;
} */


/* Desktop */

@media(min-width: 750px) {
  div.about-container {
    padding: 0 0 50px 0;
    max-width: 700px;
    margin: 0 auto;
  }

  div.about-container > h1 {
    display: none;
  }
}
div.work-container-container {
  padding: 15px 15px 75px;
}

div.work-container-container h1 {
  font-size: 2.5em;
  font-weight: 200;
  text-align: center;
  margin-bottom: 25px;
}

div.work-container-container div.section {
  overflow: hidden;
  max-height: 500vh;
  transition: all .5s;
}

div.work-container-container div.section:nth-of-type(2) {
  margin-bottom: 50px;
}

div.work-container-container div.section.close {
  max-height: 0;
}

div.work-container-container div.section-divider {
  display: flex;
  font-size: 1.25em;
  margin-bottom: 15px;
  padding-bottom: 10px;
  position: relative;
  justify-content: space-between;
  border-bottom: 1px solid #333333;
}

div.work-container-container div.section-divider div.invisible-btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

div.work-container-container div.project {
  width: 100%;
  height: 200px;
  margin-bottom: 25px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}



/* Desktop */

@media(min-width: 750px) {
  div.work-container-container {
    padding: 50px 50px 75px;
    margin: 0 auto;
    max-width: 1500px;
  }

  div.work-container-container > h1 {
    display: none;
  }

  div.work-container-container div.section {
    overflow: unset;
    max-height: unset;
    transition: all .5s;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  div.work-container-container div.section.close {
    max-height: unset;
  }

  div.work-container-container div.section-divider span:last-child{
    display: none;
  }

  div.work-container-container div.project {
    width: 32%;
    height: unset;
    margin-bottom: 25px;
    cursor: pointer;
  }

  div.work-container-container div.project:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
}
div.work-container {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  line-height: 1.6;
  position: fixed;
  overflow: scroll;
  box-sizing: border-box;
  padding: 15px 15px 75px;
  opacity: 0;
  transition: all 0.5s;
  background: rgba(255, 255, 255, .97);
}

div.work-container.on {
  opacity: 1;
}

div.work-container a,
div.work-container a:visited {
  color: #00aeef;
  text-decoration: none;
  transition: all 0.5s;
}

div.work-container a:hover {
  color: #ec008c;
}

/* div.work-container a::after {
  content: '>';
  display: inline-block;
  font-size: .7em;
  padding-left: 3px;
} */

div.work-container div.close-btn {
  display: none;
}

div.work-container img {
  width: 100%;
}

div.work-container div.mobile-device {
  width: 40%;
  position: relative;
  display: inline-block;
  margin: 5px;
}


div.work-container div.mobile-device-video {
  width: 100%;
  position: relative;
  margin: 5px;
}

div.work-container div.desktop-device {
  width: 100%;
  position: relative;
  margin: 25px 0 50px;
}

div.work-container div.mobile-device img.mobile-artwork {
  position: absolute;
  top: 3.15%;
  left: 7%;
  z-index: -1;
  width: 86.5%;
}

div.work-container div.desktop-device img.desktop-artwork {
  position: absolute;
  top: 3.15%;
  left: 8.7%;
  z-index: -1;
  width: 83%;
}


div.work-container div.mobile-device-video img.phone-graphic-video {
  width: 100% !important;
  margin: 0;
  padding: 0;
  pointer-events: none;
  width: auto;
  position: relative;;
  z-index: 2;
}

div.work-container div.mobile-device-video iFrame.video-iFrame {
  width: 94.5%;
  left: 3.15%;
  top: 4.9%;
  height: 87%;
  display: block;
  border: none;
  position: absolute;
  z-index: 0;
}





/* Desktop */

@media(min-width: 750px) {
  div.work-container {
    padding: 150px 0 75px 0;
    z-index: 25;
  }

  div.work-container > * {
    max-width: 700px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  div.work-container div.close-btn {
    background: #000000;
    position: fixed;
    top: 25px;
    left: 50px;
    width: 50px;
    height: 50px;
    display: flex;
    cursor: pointer;
    justify-content: center;
  }

  div.work-container div.close-btn img {
    width: 25px;
    align-self: center;
  }

  div.work-container div.mobile-device {
    width: 35%;
    position: relative;
    display: inline-block;
    margin: 25px;
  }
}
div.navigation-container {
  left: 0;
  bottom: 0;
  z-index: 20;
  width: 100%;
  height: 50px;
  display: flex;
  text-align: center;
  font-size: 3em;
  position: fixed;
  overflow: hidden;
  flex-flow: column;
  text-align: center;
  transition: all 0.5s;
  background: #333333;
}

div.navigation-container * {
  align-self: center;
}

div.navigation-container.open {
  height: 100%;
}

div.navigation-container div.links a,
div.navigation-container div.links a:visited {
  display: block;
  margin-bottom: 15px;
  color: #00aeef;
  text-decoration: none;
}

div.navigation-container div.links a.active {
  color: #ec008c;
  pointer-events: none;
}

div.navigation-container div.links {
  position: absolute;
  bottom: 90px;
}

div.navigation-container.open div.links a,
div.navigation-container.open div.links div {
  opacity: 0;
}

div.navigation-container.open div.links a:nth-of-type(1) {
  -webkit-animation: fadeIn 0.5s 0.2s forwards;
          animation: fadeIn 0.5s 0.2s forwards;
}

div.navigation-container.open div.links a:nth-of-type(2) {
  -webkit-animation: fadeIn 0.5s 0.25s forwards;
          animation: fadeIn 0.5s 0.25s forwards;
}

div.navigation-container.open div.links a:nth-of-type(3) {
  -webkit-animation: fadeIn 0.5s 0.375s forwards;
          animation: fadeIn 0.5s 0.375s forwards;
}

div.navigation-container.open div.links a:nth-of-type(4) {
  -webkit-animation: fadeIn 0.5s 0.5s forwards;
          animation: fadeIn 0.5s 0.5s forwards;
}

div.navigation-container.open div.links div:nth-of-type(1) {
  -webkit-animation: fadeIn 0.5s 0.625s forwards;
          animation: fadeIn 0.5s 0.625s forwards;
}

div.navigation-container div.navbtn {
  bottom: 0;
  z-index: 25;
  width: 50px;
  height: 50px;
  position: absolute;
  display: flex;
  justify-content: center;
}

div.navigation-container .desktop {
  display: none;
}

div.navigation-container div.navbtn img {
  width: 50%;
  align-self: center;
  position: absolute;
  transition: all 0.5s;
}

div.navigation-container div.navbtn img.h-icon,
div.navigation-container div.navbtn img.x-icon {
  opacity: 0;
}

div.navigation-container div.navbtn img.h-icon.on,
div.navigation-container.open div.navbtn img.x-icon,
div.navigation-container div.navbtn img.x-icon.on {
  opacity: 1;
}

div.navigation-container.open div.navbtn img.b-icon,
div.navigation-container div.navbtn img.b-icon.off,
div.navigation-container div.navbtn img.h-icon.on + img.b-icon,
div.navigation-container div.navbtn img.x-icon.on + img.b-icon {
  opacity: 0;
}

div.navigation-container div.social-icons {
  display: flex;
  margin-top: 35px;
  flex-direction: row;
  justify-content: center;
}

div.navigation-container div.social-icons a {
  display: inherit;
  margin: 0 !important;
  padding: 0 !important;
}

div.navigation-container div.social-icons img {
  width: 20px;
  margin: 0 15px;
  align-self: center;
}



/* DESKTOP */

@media(min-width: 750px) {
  div.navigation-container {
    left: 50px;
    top: 25px;
    width: calc(100% - 100px);
    height: 50px;
    display: flex;
    text-align: center;
    font-size: 3em;
    position: fixed;
    overflow: unset;
    flex-flow: row;
    text-align: left;
    transition: none;
    background: transparent;
  }
  
  div.navigation-container * {
    align-self: flex-start;
  }

  div.navigation-container.open {
    height: 50px;
  }

  div.navigation-container div.links > a:first-of-type {
    display: none;
  }

  div.navigation-container div.links a,
  div.navigation-container div.links a:visited {
    display: block;
    margin: 0 25px;
    color: #00aeef;
    transition: all 0.5s;
    text-decoration: none;
  }

  div.navigation-container.home div.links a,
  div.navigation-container.home div.links a:visited {
    color: #ffffff;
  }

  div.navigation-container div.links a:hover {
    color: #ec008c;
  }

  div.navigation-container.home div.links a:hover {
    color: #000000;
  }

  div.navigation-container div.links a.active {
    color: #ec008c;
    font-weight: 500;
    pointer-events: none;
  }

  div.navigation-container div.links {
    width: calc(100% - 50px);
    height: 50px;
    left: 50px;
    bottom: unset;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 24px;
  }

  div.links.hide-nav {
    opacity: .125;
    pointer-events: none;
  }

  div.navigation-container div.links * {
    align-self: center;
  }

  div.navigation-container.open div.links a,
  div.navigation-container.open div.links div {
    opacity: 1;
  }

  div.navigation-container.open div.links div:nth-of-type(1),
  div.navigation-container.open div.links a:nth-of-type(1),
  div.navigation-container.open div.links a:nth-of-type(2),
  div.navigation-container.open div.links a:nth-of-type(3),
  div.navigation-container.open div.links a:nth-of-type(4) {
    -webkit-animation: unset;
            animation: unset;
  }

  div.navigation-container div.navbtn {
    bottom: 0;
    width: 50px;
    height: 50px;
    position: absolute;
    display: flex;
    justify-content: center;
    background: #000000;
  }
  
  div.navigation-container .mobile {
    display: none;
  }
  
  div.navigation-container .desktop {
    display: unset;
  }

  div.navigation-container div.navbtn img {
    width: 50%;
    align-self: center;
    position: absolute;
    opacity: 0;
    transition: all 0.5s;
  }

  div.navigation-container.open div.navbtn img.x-icon,
  div.navigation-container div.navbtn img.h-icon.on,
  div.navigation-container div.navbtn img.h-icon,
  div.navigation-container div.navbtn img.x-icon,
  div.navigation-container.open div.navbtn img.x-icon.on,
  div.navigation-container div.navbtn img.x-icon.on {
    opacity: 0;
  }
  
  div.navigation-container div.navbtn img.b-icon.off,
  div.navigation-container div.navbtn img.x-icon.on + img.b-icon.off,
  div.navigation-container.open div.navbtn img.x-icon.on + img.b-icon.off,
  div.navigation-container div.navbtn img.x-icon.on + img.b-icon,
  div.navigation-container div.navbtn img.h-icon.on + img.b-icon,
  div.navigation-container.open div.navbtn img.b-icon,
  div.navigation-container div.navbtn img.b-icon {
    opacity: 1;
    cursor: pointer;
  }


  div.navigation-container div.social-icons {
    display: flex;
    margin: 0;
    flex-direction: row;
    justify-content: center;
    position: absolute;
    right: 0;
  }

  div.navigation-container div.social-icons a {
    display: inherit;
    margin: 0 !important;
    padding: 0 !important;
  }

  div.navigation-container div.social-icons img {
    width: 20px;
    margin: 0 0 0 15px;
    align-self: center;
  }
  
  div.navigation-container.home div.social-icons img {
    transition: all 0.5s;
  }

  div.navigation-container.home div.social-icons img {
    -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
  }

  div.navigation-container div.social-icons img:hover {
    -webkit-filter: hue-rotate(-228deg);
            filter: hue-rotate(-228deg);
  }

  div.navigation-container.home div.social-icons img:hover {
    -webkit-filter: brightness(0) invert(0);
            filter: brightness(0) invert(0);
  }

  div.navigation-container.work,
  div.navigation-container.about {
    position: relative;
    margin-bottom: 100px;
  }
}







@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}







@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
