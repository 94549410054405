div.work-container {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  line-height: 1.6;
  position: fixed;
  overflow: scroll;
  box-sizing: border-box;
  padding: 15px 15px 75px;
  opacity: 0;
  transition: all 0.5s;
  background: rgba(255, 255, 255, .97);
}

div.work-container.on {
  opacity: 1;
}

div.work-container a,
div.work-container a:visited {
  color: #00aeef;
  text-decoration: none;
  transition: all 0.5s;
}

div.work-container a:hover {
  color: #ec008c;
}

/* div.work-container a::after {
  content: '>';
  display: inline-block;
  font-size: .7em;
  padding-left: 3px;
} */

div.work-container div.close-btn {
  display: none;
}

div.work-container img {
  width: 100%;
}

div.work-container div.mobile-device {
  width: 40%;
  position: relative;
  display: inline-block;
  margin: 5px;
}


div.work-container div.mobile-device-video {
  width: 100%;
  position: relative;
  margin: 5px;
}

div.work-container div.desktop-device {
  width: 100%;
  position: relative;
  margin: 25px 0 50px;
}

div.work-container div.mobile-device img.mobile-artwork {
  position: absolute;
  top: 3.15%;
  left: 7%;
  z-index: -1;
  width: 86.5%;
}

div.work-container div.desktop-device img.desktop-artwork {
  position: absolute;
  top: 3.15%;
  left: 8.7%;
  z-index: -1;
  width: 83%;
}


div.work-container div.mobile-device-video img.phone-graphic-video {
  width: 100% !important;
  margin: 0;
  padding: 0;
  pointer-events: none;
  width: auto;
  position: relative;;
  z-index: 2;
}

div.work-container div.mobile-device-video iFrame.video-iFrame {
  width: 94.5%;
  left: 3.15%;
  top: 4.9%;
  height: 87%;
  display: block;
  border: none;
  position: absolute;
  z-index: 0;
}





/* Desktop */

@media(min-width: 750px) {
  div.work-container {
    padding: 150px 0 75px 0;
    z-index: 25;
  }

  div.work-container > * {
    max-width: 700px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  div.work-container div.close-btn {
    background: #000000;
    position: fixed;
    top: 25px;
    left: 50px;
    width: 50px;
    height: 50px;
    display: flex;
    cursor: pointer;
    justify-content: center;
  }

  div.work-container div.close-btn img {
    width: 25px;
    align-self: center;
  }

  div.work-container div.mobile-device {
    width: 35%;
    position: relative;
    display: inline-block;
    margin: 25px;
  }
}