div.work-container-container {
  padding: 15px 15px 75px;
}

div.work-container-container h1 {
  font-size: 2.5em;
  font-weight: 200;
  text-align: center;
  margin-bottom: 25px;
}

div.work-container-container div.section {
  overflow: hidden;
  max-height: 500vh;
  transition: all .5s;
}

div.work-container-container div.section:nth-of-type(2) {
  margin-bottom: 50px;
}

div.work-container-container div.section.close {
  max-height: 0;
}

div.work-container-container div.section-divider {
  display: flex;
  font-size: 1.25em;
  margin-bottom: 15px;
  padding-bottom: 10px;
  position: relative;
  justify-content: space-between;
  border-bottom: 1px solid #333333;
}

div.work-container-container div.section-divider div.invisible-btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

div.work-container-container div.project {
  width: 100%;
  height: 200px;
  margin-bottom: 25px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}



/* Desktop */

@media(min-width: 750px) {
  div.work-container-container {
    padding: 50px 50px 75px;
    margin: 0 auto;
    max-width: 1500px;
  }

  div.work-container-container > h1 {
    display: none;
  }

  div.work-container-container div.section {
    overflow: unset;
    max-height: unset;
    transition: all .5s;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  div.work-container-container div.section.close {
    max-height: unset;
  }

  div.work-container-container div.section-divider span:last-child{
    display: none;
  }

  div.work-container-container div.project {
    width: 32%;
    height: unset;
    margin-bottom: 25px;
    cursor: pointer;
  }

  div.work-container-container div.project:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
}